export const INIT_GET_STUDENT_LIST = 'INIT_GET_STUDENT_LIST';
export const GET_STUDENT_LIST_SUCCESS = 'GET_STUDENT_LIST_SUCCESS';
export const GET_STUDENT_LIST_FAILURE = 'GET_STUDENT_LIST_FAILURE';

export const GET_STUDENT_INFO_SUCCESS = 'GET_STUDENT_INFO_SUCCESS';
export const GET_STUDENT_INFO_FAILURE = 'GET_STUDENT_INFO_FAILURE';

export const INIT_ADD_NEW_STUDENT = 'INIT_ADD_NEW_STUDENT';
export const ADD_NEW_STUDENT_SUCCESS = 'ADD_NEW_STUDENT_SUCCESS';
export const ADD_NEW_STUDENT_FALIURE = 'ADD_NEW_STUDENT_FALIURE';

export const SET_GRADE = 'SET_GRADE';

export const INIT_GRADE_INFO = 'INIT_GRADE_INFO';
export const GET_GRADE_INFO_SUCCESS = 'GET_GRADE_INFO_SUCCESS';
export const GET_GRADE_INFO_FAILURE = 'GET_GRADE_INFO_FAILURE';
export const RESET_GRADE_INFO = 'RESET_GRADE_INFO';

export const CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';

export const DRAWER_VISIBLITY = 'DRAWER_VISIBLITY';

export const SET_STUDENT_NAME = 'SET_STUDENT_NAME';

export const TOGGLE_NEW_STUDENT_MODAL = 'TOGGLE_NEW_STUDENT_MODAL';

export const UPDATE_AUTH_INFO = 'UPDATE_AUTH_INFO';

export const SHOW_NEW_STUDENT_ALERT = 'SHOW_NEW_STUDENT_ALERT';
export const CLOSE_NEW_STUDENT_ALERT = 'CLOSE_NEW_STUDENT_ALERT';

export const SHOW_ACCOUNT_ALERT = 'SHOW_ACCOUNT_ALERT';
export const SHOW_LOGIN_ALERT = 'SHOW_LOGIN_ALERT';
export const SHOW_STUDENT_ALERT = 'SHOW_STUDENT_ALERT';
export const CLOSE_STUDENT_ALERT = 'CLOSE_STUDENT_ALERT';

export const UPDATE_SKILL = 'UPDATE_SKILL';
export const UPDATE_TEACHER = 'UPDATE_TEACHER';

export const INIT_SAVE_OR_DELETE_STUDENT = 'INIT_SAVE_OR_DELETE_STUDENT';
export const INIT_GET_STUDENT_INFO = 'INIT_GET_STUDENT_INFO';
export const VIEW_STUDENT_INFO = 'VIEW_STUDENT_INFO';
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';

export const VALIDATE_RESET_TOKEN_SUCCESS = 'VALIDATE_RESET_TOKEN_SUCCESS';
export const VALIDATE_RESET_TOKEN_FAILURE = 'VALIDATE_RESET_TOKEN_FAILURE';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';