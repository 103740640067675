import {
    SHOW_LOGIN_ALERT
} from '../Constants/ActionTypes';
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS, FAILED_TO_FIND_ACCOUNT, FIREBASE_INVALID_CREDENTIAL } from '../Constants/TextConstants';
import { fetch } from '../Services';

export function showLoginAlert(alertType, alertMessage) {
    if (alertMessage.includes(FIREBASE_INVALID_CREDENTIAL)) {
        alertMessage = FAILED_TO_FIND_ACCOUNT;
    }

    return {
        type: SHOW_LOGIN_ALERT,
        alertType,
        alertMessage
    }
}

export function sendEmailResetPassword(email) {
    return async (dispatch) => {
        const url = '/login/email-password-reset';
        const options = {
            method: 'POST',
            body: JSON.stringify({email})
        }

        if (!email) {
            return dispatch(showLoginAlert(ALERT_TYPE_ERROR, "Email not provided"))
        }

        try {
            const response = await fetch(url, options);
            dispatch(showLoginAlert(ALERT_TYPE_SUCCESS, "Email sent"));
        } catch (error) {
            dispatch(showLoginAlert(ALERT_TYPE_ERROR, "Failed to send email"));
        }
    }
}

export function findTeacherName(uniqueId) {
    return async () => {
        const url = `/login/teacher-name/${uniqueId}`;
        const options = {
            method: 'GET'
        };

        const response = await fetch(url, options);
        const responseData = await response.text();

        return responseData;
    }
}
