import { debounce } from "lodash";
import { GET_GRADE_INFO_SUCCESS, INIT_GRADE_INFO, SET_GRADE } from "../Constants/ActionTypes";
import { fetch } from "../Services";

export const setGrade = (grade) => ({
    type: SET_GRADE,
    grade
})

export const getGradeInfoSuccess = (data) => {
    return {
        type: GET_GRADE_INFO_SUCCESS,
        payload: data
    }
};

export const getGradeInfoFailure = () => {
    return {
        type: GET_GRADE_INFO_SUCCESS
    }
};

export const fetchGradeInfo = (grade, teacherName) => {
    return (dispatch) => {
        getGradeInfo(dispatch, grade, teacherName)
    }
};

const getGradeInfo = debounce(async (dispatch, grade, teacherName) => {
    const url = `/students/get-students-by-grade/${grade}/${teacherName}`
    const options = {
        method: 'GET'
    }

    dispatch({
        type: INIT_GRADE_INFO
    })

    try {
        const response = await fetch(url, options)
        const responseData = await response.json();
        dispatch(getGradeInfoSuccess(responseData));
    } catch (error) {
        dispatch(getGradeInfoFailure())
    }
}, 500);