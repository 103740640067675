import { combineReducers } from "redux";

import appHeaderReducer from './AppHeaderReducer';
import gradeInfoReducer from "./GradeInfoReducer";
import homeReducer from "./HomeReducer";
import newAccountReducer from "./NewAccountReducer";
import newStudentReducer from "./NewStudentReducer";
import resetPasswordReducer from "./ResetPasswordReducer";
import studentInfoReducer from "./StudentInfoReducer";

export function createReducer() {
    const reducers = {
        home: homeReducer,
        studentInfo: studentInfoReducer,
        gradeInfo: gradeInfoReducer,
        newStudent: newStudentReducer,
        newAccount: newAccountReducer,
        resetPassword: resetPasswordReducer,
        appHeader: appHeaderReducer
    };

    return combineReducers(reducers, {});
}
