import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, Modal, OutlinedInput, ThemeProvider, Typography, createTheme } from "@mui/material";
import { initializeApp } from 'firebase/app';
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTeacher, showAccountAlert } from "../../../Actions/NewAccountActions";
import { ALERT_TYPE_ERROR, ALERT_TYPE_WARNING, CREATE_ACCOUNT_TITLE, HOME_PATH } from "../../../Constants/TextConstants";

const NewAccount = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const showAlert = useSelector(state => state.newAccount.showAlert);
    const alertMessage = useSelector(state => state.newAccount.alertMessage);
    const alertType = useSelector(state => state.newAccount.alertType);

    const firebaseConfig = {
        apiKey: "AIzaSyBoTLmDOj0hJOv02lAF2PVAfGIYJQ_DAhI",
        authDomain: "nsnumeracyproject.firebaseapp.com",
        projectId: "nsnumeracyproject",
        storageBucket: "nsnumeracyproject.appspot.com",
        messagingSenderId: "980983321546",
        appId: "1:980983321546:web:fdd0a62d74873caf06b533",
        measurementId: "G-R1KMMTTCXE"
    };

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    const createAccount = () => {
        if (password !== confirmPassword) {
            return dispatch(showAccountAlert(ALERT_TYPE_ERROR, 'Confirm password must match password'))
        }

        if (!email || !firstName || !lastName || !password || !confirmPassword) {
            dispatch(showAccountAlert(ALERT_TYPE_ERROR, 'All fields are required'))
        } else {
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    sendEmailVerification(userCredential.user)
                        .then(() => {
                            onAuthStateChanged(auth, (user) => {
                                if (user) {
                                    dispatch(showAccountAlert(ALERT_TYPE_WARNING, 'Account created successfully!' +
                                        ' Please check your inbox for a verification email to complete the registration process.'))
                                    const interval = setInterval(async () => {
                                        await user.reload();
                                        if (user.emailVerified) {
                                            clearInterval(interval);
                                            dispatch(createTeacher(`${firstName} ${lastName}`, user.uid));
                                            window.location.assign(HOME_PATH)
                                        }
                                    }, 3000)
                                }
                            });
                        });
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    dispatch(showAccountAlert(ALERT_TYPE_ERROR, errorMessage))
                });
        }
    }

    const theme = createTheme({
        palette: {
            base: {
                main: '#D4D4D4',
                dark: 'grey'
            }
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Modal open>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 10,
                        borderRadius: '1rem',
                        p: 4
                    }}
                >
                    <Grid item xs={12}>
                        <Typography variant='h4' mt={2} mb={1}>{CREATE_ACCOUNT_TITLE}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {showAlert ? <Alert variant='filled' severity={alertType} sx={{ marginBottom: '1rem' }}>
                            {alertMessage}
                        </Alert> : null}
                    </Grid>
                    <Grid container item rowGap={1}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Email</InputLabel>
                                <OutlinedInput
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    label='Email'
                                />
                            </FormControl>
                        </Grid>
                        <Grid container columnGap={1}>
                            <Grid item xs={5.88}>
                                <FormControl variant="outlined">
                                    <InputLabel>First Name</InputLabel>
                                    <OutlinedInput
                                        onChange={(e) => { setFirstName(e.target.value) }}
                                        label='First Name'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={5.88}>
                                <FormControl variant="outlined">
                                    <InputLabel>Last Name</InputLabel>
                                    <OutlinedInput
                                        onChange={(e) => { setLastName(e.target.value) }}
                                        label='Last Name'
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} columnGap={1}>
                            <Grid item xs={5.88}>
                                <FormControl variant="outlined">
                                    <InputLabel>Password</InputLabel>
                                    <OutlinedInput
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={(e) => { setPassword(e.target.value) }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => { setShowPassword(!showPassword) }}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label='Password'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={5.88}>
                                <FormControl variant="outlined">
                                    <InputLabel>Confirm Password</InputLabel>
                                    <OutlinedInput
                                        type={showPassword ? 'text' : 'password'}
                                        error={password !== confirmPassword}
                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => { setShowPassword(!showPassword) }}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label='Confirm Password'
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent={'end'} mb={1} mr={1}>
                            <Button
                                variant='contained'
                                color='base'
                                onClick={() => { createAccount() }}
                            >
                                Create Account
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </ThemeProvider>
    )
}

export default NewAccount;
