import react, { createContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { getAuth } from "firebase/auth";
import Home from "./Home/Home";
import StudentInfoPage from './StudentInfo/StudentInfoPage'
import GradeInfoPage from "./GradeInfo/GradeInfoPage";
import { useDispatch, useSelector } from "react-redux";
import NewStudentModal from "./Common/NewStudentModal";
import { CREATE_ACCOUNT_PATH, HOME_PATH, LOGIN_PATH } from "../../Constants/TextConstants";
import Login from "./Login/Login";
import { findTeacherName, validateToken } from "../../Actions/HomeActions";
import NewAccount from "./NewAccount/NewAccount";
import { getCookie } from "../../Utility/CommonUtils";
import ResetPassword from "./ResetPassword/ResetPassword";
import PrivateRoute from "../App/PrivateRoute";
import { AuthProvider } from "../App/AuthProvider";
import AppHeader from "../App/AppHeader";
import AppFooter from "../App/AppFooter";

const Body = () => {
    const isAddNewStudentModalOpen = useSelector((state) => state.appHeader.isNewStudentModalOpen)
    
    return (
        <AuthProvider>
            <Router>
                <AppHeader />
                <Routes>
                    <Route path={'/reset-password/:token'} element={<PrivateRoute><ResetPassword /></PrivateRoute>} />
                    <Route path={CREATE_ACCOUNT_PATH} element={<NewAccount />} />
                    <Route default path={LOGIN_PATH} element={<Login />} />
                    <Route path={HOME_PATH} element={<PrivateRoute><Home /></PrivateRoute>} />
                    <Route path={'/student/:studentName'} element={<PrivateRoute><StudentInfoPage /></PrivateRoute>} />
                    <Route path={'/grades/:grade'} element={<PrivateRoute><GradeInfoPage /></PrivateRoute>} />
                </Routes>
            </Router>
            <NewStudentModal isOpen={isAddNewStudentModalOpen} />
            <AppFooter />
        </AuthProvider>
    )
}

export default Body;
