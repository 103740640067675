export const METRICS_TITLE = 'Numeracy Project';
export const CREATE_ACCOUNT_TITLE = 'Create Account';

export const KINDERGARTEN = 'Kindergarten';
export const GRADE_1 = '1st Grade';
export const GRADE_2 = '2nd Grade';
export const GRADE_3 = '3rd Grade';
export const GRADE_4 = '4th Grade';
export const GRADE_5 = '5th Grade';
export const GRADE_6 = '6th Grade';

export const KINDERGARTEN_PAGE = 'kindergarten';
export const GRADE_1_PAGE = '1st_grade';
export const GRADE_2_PAGE = '2nd_grade';
export const GRADE_3_PAGE = '3rd_grade';
export const GRADE_4_PAGE = '4th_grade';
export const GRADE_5_PAGE = '5th_grade';
export const GRADE_6_PAGE = '6th_grade';

export const ADD_NEW_STUDENT_SUBMIT = 'Create Student';

export const DELETE = 'Delete';
export const SAVE = 'Save';
export const CONFIRM_SAVE = 'Are you sure you want to save your changes?';
export const CONFIRM_DELETE = 'Are you sure you want to delete this student?';

export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const ALERT_TYPE_ERROR = 'error';
export const ALERT_TYPE_SUCCESS = 'success';
export const ALERT_TYPE_WARNING = 'warning';

export const FIREBASE_EMAIL_IN_USE = 'auth/email-already-in-use'
export const EMAIL_IN_USE = 'This account already exists'
export const FIREBASE_INVALID_CREDENTIAL = 'auth/invalid-credential';
export const FAILED_TO_FIND_ACCOUNT = 'Failed to find account';
export const EMAIL_NOT_VERIFIED = 'Please verify your email before logging in. Check your inbox for the verification email we sent to you.';

export const SIGN_IN = 'Sign in';
export const RESET_PASSWORD = 'Reset Password'

export const HOME_PATH = '/';
export const LOGIN_PATH = '/login';
export const CREATE_ACCOUNT_PATH = '/create-account';

export const FAILED_TO_VALIDATE_RESET_PASSWORD_TOKEN = 'Failed to find account for password reset.';
export const SUCCESSFULLY_RESET_PASSWORD = 'Successfully reset password. Please navigate to sign in.';
export const FAILED_TO_RESET_PASSWORD = 'Failed to reset password.';