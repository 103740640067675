import { RESET_PASSWORD_FAILURE, RESET_PASSWORD_SUCCESS, VALIDATE_RESET_TOKEN_FAILURE, VALIDATE_RESET_TOKEN_SUCCESS } from "../Constants/ActionTypes";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS, FAILED_TO_RESET_PASSWORD, FAILED_TO_VALIDATE_RESET_PASSWORD_TOKEN, SUCCESSFULLY_RESET_PASSWORD } from "../Constants/TextConstants";
import resetPasswordState from "../State/ResetPasswordState";

function validateResetTokenSuccess(state) {
    return {
        ...state,
        showAlert: false,
        alertType: '',
        alertMessage: ''
    }
}

function validateResetTokenFailure(state) {
    return {
        ...state,
        showAlert: true,
        alertType: ALERT_TYPE_ERROR,
        alertMessage: FAILED_TO_VALIDATE_RESET_PASSWORD_TOKEN
    }
}

function onResetPasswordSuccess(state) {
    return {
        ...state,
        showAlert: true,
        alertType: ALERT_TYPE_SUCCESS,
        alertMessage: SUCCESSFULLY_RESET_PASSWORD
    }
}

function onResetPasswordFailure(state) {
    return {
        ...state,
        showAlert: true,
        alertType: ALERT_TYPE_ERROR,
        alertMessage: FAILED_TO_RESET_PASSWORD
    }
}

export default function ResetPasswordReducer(state = resetPasswordState(), action) {
    switch (action.type) {
        case VALIDATE_RESET_TOKEN_SUCCESS:
            return validateResetTokenSuccess(state);
        case VALIDATE_RESET_TOKEN_FAILURE:
            return validateResetTokenFailure(state);
        case RESET_PASSWORD_SUCCESS:
            return onResetPasswordSuccess(state);
        case RESET_PASSWORD_FAILURE:
            return onResetPasswordFailure(state);
        default:
            return state;
    }
}