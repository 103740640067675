import { SHOW_ACCOUNT_ALERT } from "../Constants/ActionTypes";
import { EMAIL_IN_USE, FIREBASE_EMAIL_IN_USE } from "../Constants/TextConstants";
import { fetch } from "../Services";

export function showAccountAlert(alertType, alertMessage) {
    if (alertMessage.includes(FIREBASE_EMAIL_IN_USE)) {
        alertMessage = EMAIL_IN_USE;
    }

    return {
        type: SHOW_ACCOUNT_ALERT,
        alertType,
        alertMessage
    }
}

export function createTeacher(teacherName, uniqueId) {
    return async () => {
        const url = '/login/create-teacher';
        const options = {
            method: 'POST',
            body: JSON.stringify({
                teacherName,
                uniqueId
            })
        };

        await fetch(url, options);
    }
}
