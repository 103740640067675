import { Checkbox, CircularProgress, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateTeacher } from "../../../Actions/AppHeaderActions";
import { fetchGradeInfo, setGrade } from "../../../Actions/GradeInfoActions";
import { RESET_GRADE_INFO } from "../../../Constants/ActionTypes";
import "../../../Styles/gradeInfo.css";
import { CapitalizeTitle } from "../../../Utility/CommonUtils";
import PopulateGradeInfo from "../Home/PopulateGradeInfo";

const GradeInfoPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gradeInfo = useSelector((state) => state.gradeInfo.gradeInformation);
  const teacherName = useSelector((state) => state.appHeader.teacherName);
  const loadingGradeInfo = useSelector(
    (state) => state.gradeInfo.loadingGradeInfo
  );
  const { grade } = useParams();
  let auth = getAuth();

  const updateAuth = async () => {
    await new Promise((resolve) => {
      auth.onAuthStateChanged((user) => {
        resolve(user);
      });
    });
  };

  const loadTeacherName = async () => {
    await updateAuth();

    dispatch(updateTeacher(auth.currentUser.uid));
  };

  useEffect(() => {
    if (!teacherName) {
      loadTeacherName();
    }
  }, []);

  let rows = [];
  let columns = [
    {
      field: "index",
      headerName: "",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => {
        return (
          <PopulateGradeInfo
            studentList={[
              {
                studentName: params.value,
              },
            ]}
            style={{
              display: "flex",
              justifyContent: "center",
              listStyle: "none",
              padding: 0,
              flexWrap: "wrap",
              margin: 0
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    dispatch({
      type: RESET_GRADE_INFO,
    });
    dispatch(setGrade(grade.replace("_", " ")));
    if (teacherName) {
      dispatch(fetchGradeInfo(grade, teacherName));
    }
  }, [navigate, teacherName]);

  if (columns.length === 2 && gradeInfo.length > 0) {
    const skillCodeListLength = gradeInfo[0]?.skillCodeDTOList.length;
    for (let j = 0; j < skillCodeListLength; j++) {
      columns.push({
        key: j,
        field: "SkillCode" + (j + 1),
        headerName: `Skill Code ${gradeInfo[0]?.skillCodeDTOList[j]?.skillCode}`,
        width: 150,
        renderCell: (params) => {
          return isSkillCodeComplete(params);
        },
      });
    }
  }

  const isSkillCodeComplete = (params) => {
    const field = params.colDef.field;
    return <Checkbox disabled checked={params.row[field]} />;
  };

  const skillCodes = gradeInfo?.flatMap((item) => item.skillCodeDTOList);
  const offset = skillCodes[0]?.skillCodeId - 1;

  rows = new Array(gradeInfo.length).fill().map((_, index) => ({
    id: 1 + index,
    name: CapitalizeTitle(gradeInfo[index].studentName),
  }));

  for (let i = 0; i < rows.length; i++) {
    if (gradeInfo.length > 0) {
      for (let j = 0; j < gradeInfo[i]?.skillCodeDTOList.length; j++) {
        rows[i][`SkillCode${j + 1}`] =
          gradeInfo[i]?.skillCodeDTOList[j]?.completionDate !== null;
      }
    }
  }

  let stageOne = [];

  skillCodes
    .filter((item) => item.stageId === 1)
    .forEach((skillCode) => {
      stageOne.push({
        field: "SkillCode" + (skillCode.skillCodeId - offset),
        flex: 1,
      });
    });

  let stageTwo = [];

  skillCodes
    .filter((item) => item.stageId === 2)
    .forEach((skillCode) => {
      stageTwo.push({
        field: "SkillCode" + (skillCode.skillCodeId - offset),
        flex: 1,
      });
    });

  let stageThree = [];

  skillCodes
    .filter((item) => item.stageId === 3)
    .forEach((skillCode) => {
      stageThree.push({
        field: "SkillCode" + (skillCode.skillCodeId - offset),
        flex: 1,
      });
    });

  let stageFour = [];

  skillCodes
    .filter((item) => item.stageId === 4)
    .forEach((skillCode) => {
      stageFour.push({
        field: "SkillCode" + (skillCode.skillCodeId - offset),
        flex: 1,
      });
    });

  let stageFive = [];

  skillCodes
    .filter((item) => item.stageId === 5)
    .forEach((skillCode) => {
      stageFive.push({
        field: "SkillCode" + (skillCode.skillCodeId - offset),
        flex: 1,
      });
    });

  let stageSix = [];

  skillCodes
    .filter((item) => item.stageId === 6)
    .forEach((skillCode) => {
      stageSix.push({
        field: "SkillCode" + (skillCode.skillCodeId - offset),
        flex: 1,
      });
    });

  return (
    <Box sx={{ width: "930rem", bgcolor: "white" }} mt={3}>
      <DataGrid
        fullWidth
        autoHeight
        loading={loadingGradeInfo}
        slots={{
          loadingOverlay: () => (
            <Grid display="flex" justifyContent="flex-start">
              <CircularProgress
                size={30}
                sx={{
                  color: "#96281B",
                  margin: "0.75rem 2rem",
                }}
              />
            </Grid>
          ),
        }}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        hideFooterPagination
        disableColumnResize
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
        }
        columnGroupingModel={[
          {
            groupId: "Stage 1",
            children: stageOne,
          },
          {
            groupId: "Stage 2",
            children: stageTwo,
          },
          {
            groupId: "Stage 3",
            children: stageThree,
          },
          {
            groupId: "Stage 4",
            children: stageFour,
          },
          {
            groupId: "Stage 5",
            children: stageFive,
          },
          {
            groupId: "Stage 6",
            children: stageSix,
          },
        ]}
      />
    </Box>
  );
};

export default GradeInfoPage;
