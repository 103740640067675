import {
    SHOW_LOGIN_ALERT
} from "../Constants/ActionTypes";
import homeState from "../State/HomeState";

function showAlertMessage(state, action) {
    return {
        ...state,
        showAlert: true,
        alertType: action.alertType,
        alertMessage: action.alertMessage
    }
}

export default function HomeReducer(state = homeState(), action) {
    switch (action.type) {
        case SHOW_LOGIN_ALERT:
            return showAlertMessage(state, action);
        default:
            return state;
    }
}