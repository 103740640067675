import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { createReducer } from "../Reducers";

const reducer = createReducer();
const Store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(thunk)
});

export default Store;