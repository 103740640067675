import { CLOSE_CONFIRMATION_MODAL, CLOSE_STUDENT_ALERT, DELETE_STUDENT_SUCCESS, GET_STUDENT_INFO_FAILURE, GET_STUDENT_INFO_SUCCESS, INIT_GET_STUDENT_INFO, INIT_SAVE_OR_DELETE_STUDENT, OPEN_CONFIRMATION_MODAL, SET_STUDENT_NAME, SHOW_STUDENT_ALERT, UPDATE_SKILL, VIEW_STUDENT_INFO } from '../Constants/ActionTypes';
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from '../Constants/TextConstants';
import { fetch } from '../Services';
import { CapitalizeTitle } from '../Utility/CommonUtils';
import { getStudentList } from './AppHeaderActions';

export const setStudentName = (data) => ({
    type: SET_STUDENT_NAME,
    payload: data
})

export const closeConfirmationModal = () => ({
    type: CLOSE_CONFIRMATION_MODAL
})

export const openConfirmationModal = (confirmationType) => ({
    type: OPEN_CONFIRMATION_MODAL,
    payload: confirmationType
})

export const getStudentInfoSuccess = (data) => ({
    type: GET_STUDENT_INFO_SUCCESS,
    payload: data
});

export const getStudentInfoFailure = () => ({
    type: GET_STUDENT_INFO_FAILURE,
    payload: []
});

export const updateSkillStatus = (masteredDataPoints) => ({
    type: UPDATE_SKILL,
    payload: masteredDataPoints
})

export function saveStudentSkillChanges(changes) {
    return async (dispatch) => {
        const url = '/students/update-student-information'
        const options = {
            method: 'PUT',
            body: JSON.stringify(changes)
        }

        dispatch(initSaveOrDelete());

        try {
            await fetch(url, options);
            dispatch(showStudentAlert(ALERT_TYPE_SUCCESS, 'Successfully updated skill codes'))
        } catch (error) {
            dispatch(showStudentAlert(ALERT_TYPE_ERROR, 'Failed to update skill codes'))
        }
    }
}

export function deleteStudent(studentName, teacherName) {
    return async (dispatch) => {
        const url = `/students/delete-student/${teacherName}/${studentName}`
        const options = {
            method: 'DELETE'
        }

        dispatch(initSaveOrDelete());
        
        try {
            await fetch(url, options);
            dispatch({
                type: DELETE_STUDENT_SUCCESS
            })
            dispatch(getStudentList(teacherName))
        } catch (error) {
            dispatch(closeConfirmationModal())
            dispatch(showStudentAlert(ALERT_TYPE_ERROR, "Failed to delete"))
        }
    }
}

export function viewStudentInfo(name) {
    return ({
        type: VIEW_STUDENT_INFO,
        name
    })
}

export function getStudentInfo(name, teacherName) {
    return async (dispatch) => {
        const url = `/students/get-student-information/${teacherName}/${name}`;
        const options = {
            method: 'GET'
        }

        dispatch({
            type: INIT_GET_STUDENT_INFO
        })

        try {
            const response = await fetch(url, options);
            const responseData = await response.json();
            dispatch(getStudentInfoSuccess(responseData));
        } catch (error) {
            dispatch(getStudentInfoFailure());
        }
    }
}

export function downloadStudentReport(name, teacherName) {
    return async (dispatch) => {
        const url = `/students/download-report/${teacherName}/${name}`
        const options = {
            method: 'GET'
        }

        const studentName = CapitalizeTitle(name.replace("_", " ")).replace(" ", "")
        const fileName = `studentReport-${studentName}.xlsx`
        
        try {
            const response = await fetch(url, options);
            const responseData = await response.blob();
            const responseUrl = window.URL.createObjectURL(responseData);
            const a = document.createElement('a');

            a.href = responseUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            dispatch(showStudentAlert(ALERT_TYPE_SUCCESS, 'Successfully downloaded report'))
        } catch {
            dispatch(showStudentAlert(ALERT_TYPE_ERROR, 'Failed to download report'))
        }
    }
}

export function showStudentAlert(alertType, alertMessage) {
    return {
        type: SHOW_STUDENT_ALERT,
        alertType,
        alertMessage
    }
}

export function closeStudentAlert() {
    return {
        type: CLOSE_STUDENT_ALERT
    }
}

export function initSaveOrDelete() {
    console.log('dispatch')
    return ({
        type: INIT_SAVE_OR_DELETE_STUDENT
    })
}