import { encode } from "base-64";
import { RESET_PASSWORD_FAILURE, RESET_PASSWORD_SUCCESS, VALIDATE_RESET_TOKEN_FAILURE, VALIDATE_RESET_TOKEN_SUCCESS } from "../Constants/ActionTypes";
import { fetch } from "../Services";

export function validateResetToken(token) {
    return async (dispatch) => {
        const url = `/login/validate-reset-token/${token}`;
        const options = {
            method: 'GET'
        };

        try {
            const response = await fetch(url, options);
            const responseData = await response;
            
            dispatch({
                type: VALIDATE_RESET_TOKEN_SUCCESS
            })
        } catch (error) {
            dispatch({
                type: VALIDATE_RESET_TOKEN_FAILURE
            })
        }
    }
}

export function resetUserPassword(token, newPassword) {
    return async (dispatch) => {
        const url = '/login/reset-password';
        const options = {
            method: 'PUT',
            body: JSON.stringify({
                token,
                password: encode(newPassword)
            })
        };

        try {
            await fetch(url, options);

            dispatch({
                type: RESET_PASSWORD_SUCCESS
            })
        } catch {
            dispatch({
                type: RESET_PASSWORD_FAILURE
            })
        }
    }
}