import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Grid, IconButton, Modal, ThemeProvider, createTheme } from '@mui/material';
import { getAuth } from 'firebase/auth';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getStudentList, updateTeacher } from '../../../Actions/AppHeaderActions';
import { closeConfirmationModal, deleteStudent, getStudentInfo, saveStudentSkillChanges, showStudentAlert } from '../../../Actions/StudentInfoActions';
import { DELETE, HOME_PATH, SAVE } from '../../../Constants/TextConstants';

const ConfirmationModal = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = getAuth();
    const { studentName } = useParams();
    const teacherName = useSelector((state) => state.appHeader.teacherName);
    const masteredDataPoints = useSelector((state) => state.studentInfo.masteredDataPoints);
    const loading = useSelector((state) => state.studentInfo.loadingConfirmation)

    const updateAuth = async () => {
        await new Promise((resolve) => {
            auth.onAuthStateChanged((user) => {
                resolve(user);
            });
        });
    }

    const loadTeacherName = async () => {
        await updateAuth();

        dispatch(updateTeacher(auth.currentUser.uid))
    }

    useEffect(() => {
        if (!teacherName) {
            loadTeacherName();
        } else {
            dispatch(getStudentList(teacherName))
        }
    }, []);

    const saveStudentInfoChanges = async () => {
        console.log(loading)
        await dispatch(saveStudentSkillChanges(masteredDataPoints))
        console.log(loading)
        dispatch(closeConfirmationModal())
        console.log(loading)
        if (teacherName) {
            dispatch(getStudentInfo(studentName, teacherName))
        }
    }

    const confirmAction = async () => {
        switch (props.buttonText) {
            case SAVE:
                saveStudentInfoChanges();
                break;
            case DELETE:
                if (teacherName) {
                    await dispatch(deleteStudent(studentName, teacherName))
                    navigate(HOME_PATH);
                } else {
                    dispatch(showStudentAlert(ALERT_TYPE_ERROR, 'Unable to find user credentials'))
                }
                break;
        }
    }

    const theme = createTheme({
        palette: {
            base: {
                main: '#D4D4D4',
                dark: 'grey'
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={props.isOpen}
                onClose={() => { dispatch(closeConfirmationModal()) }}
            >
                <Box sx={{
                    maxWidth: '25rem',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    borderRadius: '1rem',
                    p: 4
                }}>
                    <IconButton
                        onClick={() => { dispatch(closeConfirmationModal()) }}
                        sx={{
                            position: 'absolute',
                            top: 4,
                            right: 4,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Grid container item columnGap={'1rem'}>
                        <h2>{props.message}</h2>
                    </Grid>
                    <Grid container display={'flex'} marginTop={'1rem'}>
                        <Grid container item justifyContent={'flex-end'} marginTop={'1rem'}>
                            <Box sx={{ m: 1, position: 'relative' }}> 
                                <Button
                                    variant="contained"
                                    color="base"
                                    onClick={() => confirmAction()}
                                    disabled={loading}
                                >
                                    {props.buttonText}
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: '#96281B',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </ThemeProvider>
    )
}

export default ConfirmationModal;
