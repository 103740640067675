import { GET_GRADE_INFO_FAILURE, GET_GRADE_INFO_SUCCESS, INIT_GRADE_INFO, RESET_GRADE_INFO, SET_GRADE } from "../Constants/ActionTypes";
import gradeInfoState from "../State/GradeInfoState";

function setGrade(state, action) {
    return {
        ...state,
        grade: action.grade
    }
}

function onInitGradeInfo(state) {
    return {
        ...state,
        loadingGradeInfo: true
    }
}

function getGradeInfoSuccess(state, action) {
    return {
        ...state,
        loadingGradeInfo: false,
        gradeInformation: action.payload
    }
}

function getGradeInfoFailure(state) {
    return {
        ...state,
        loadingGradeInfo: false,
        gradeInformation: []
    }
}

function onResetGradeInfo(state) {
    return {
        ...state,
        gradeInformation: []
    }
}

export default function GradeInfoReducer(state = gradeInfoState(), action) {
    switch (action.type) {
        case SET_GRADE:
            return setGrade(state, action);
        case INIT_GRADE_INFO:
            return onInitGradeInfo(state);
        case GET_GRADE_INFO_SUCCESS:
            return getGradeInfoSuccess(state, action);
        case GET_GRADE_INFO_FAILURE:
            return getGradeInfoFailure(state);
        case RESET_GRADE_INFO:
            return onResetGradeInfo(state);
        default:
            return state
    }
}