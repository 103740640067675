import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Grid } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getStudentList, updateTeacher } from "../../../Actions/AppHeaderActions";
import { downloadStudentReport, getStudentInfo, openConfirmationModal, setStudentName, showStudentAlert, updateSkillStatus } from "../../../Actions/StudentInfoActions";
import { ALERT_TYPE_ERROR, CONFIRM_DELETE, CONFIRM_SAVE, DELETE, SAVE } from "../../../Constants/TextConstants";
import '../../../Styles/studentInfo.css';
import ConfirmationModal from "../Common/ConfirmationModal";

const StudentInfoPage = () => {
    const dispatch = useDispatch();
    const auth = getAuth();
    const { studentName } = useParams();
    const teacherName = useSelector((state) => state.appHeader.teacherName);
    const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
    const masteredDataPoints = useSelector((state) => state.studentInfo.masteredDataPoints);
    const isConfirmationModalOpen = useSelector((state) => state.studentInfo.isConfirmationModalOpen);
    const confirmationType = useSelector((state) => state.studentInfo.confirmationType);
    const loadingStudentInfo = useSelector((state) => state.studentInfo.loadingStudentInfo);
    const [buttonText, setButtonText] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const updateAuth = async () => {
        await new Promise((resolve) => {
            auth.onAuthStateChanged((user) => {
                resolve(user);
            });
        });
    }

    const loadTeacherName = async () => {
        await updateAuth();

        dispatch(updateTeacher(auth.currentUser.uid))
    }

    useEffect(() => {
        if (!teacherName) {
            loadTeacherName();
        } else {
            dispatch(getStudentList(teacherName))
        }
    }, []);

    useEffect(() => {
        dispatch(setStudentName(studentName.replace('_', ' ')));
        if (teacherName) {
            dispatch(getStudentInfo(studentName, teacherName));
        }
    }, [studentName, teacherName])

    const theme = createTheme({
        palette: {
            delete: {
                main: '#96281B',
                dark: '#ec644b'
            },
            save: {
                main: '#b2de27',
                dark: '#7FFF00'
            },
            download: {
                main: 'white',
                dark: 'lightgrey'
            }
        },
    });

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const deleteStudent = () => {
        setButtonText(DELETE);
        setConfirmationMessage(CONFIRM_DELETE)
        dispatch(openConfirmationModal(DELETE));
    }

    const saveStudentInfoChanges = async () => {
        setButtonText(SAVE);
        setConfirmationMessage(CONFIRM_SAVE)
        dispatch(openConfirmationModal(SAVE));
    }

    const updateSkill = (event, dataPoint) => {
        if (event.target.checked && event.target.checked !== event.target.defaultChecked) {
            dispatch(updateSkillStatus([...masteredDataPoints, dataPoint]));
        } else if (!event.target.checked && event.target.checked !== event.target.defaultChecked) {
            dispatch(updateSkillStatus([...masteredDataPoints, dataPoint]));
        } else {
            dispatch(updateSkillStatus(masteredDataPoints.filter(masteredDataPoint => masteredDataPoint.skillId !== dataPoint.skillId)));
        }
    }

    const populateDataPointName = (dataPointIndex) => {
        if (dataPointIndex == 0) {
            return 'Baseline';
        } else {
            return 'Data Point ' + dataPointIndex;
        }
    }

    const populateSkillCodeName = (skillCode, skillCodeIndex) => {
        if (skillCode.completionDate === null) {
            return ('Skill Code ' + (skillCodeIndex + 1))
        }

        return ('Skill Code ' + (skillCodeIndex + 1) + ` | (${skillCode.completionDate})`)
    }

    const populateStageName = (stageId) => {
        return 'Stage ' + stageId
    }

    const isStageChecked = (stage) => {
        const incompleteStages = stage.skillCodeDTOs.filter(skillCode => skillCode.completionDate === null)

        if (incompleteStages.length > 0) {
            return false
        } else {
            return true
        }
    }

    const handleDownloadReport = () => {
        if (teacherName) {
            dispatch(downloadStudentReport(studentName, teacherName))
        } else {
            dispatch(showStudentAlert(ALERT_TYPE_ERROR, 'Unable to find user credentials'))
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container>
                <Grid container item margin={'1rem'} mt={3} columnGap={'1rem'}>
                    <Grid item xs={9} justifyContent={'start'}>
                        <Button
                            variant='contained'
                            color='download'
                            onClick={handleDownloadReport}
                        >
                            Download Report
                        </Button>
                    </Grid>
                    <Grid container item xs={1} justifyContent={'end'}>
                        <Button
                            variant='contained'
                            color="save"
                            disabled={masteredDataPoints?.length === 0}
                            onClick={saveStudentInfoChanges}
                        >
                            {SAVE}
                        </Button>
                    </Grid>
                    <Grid container item xs={1} justifyContent={'end'}>
                        <Button
                            variant='contained'
                            color="delete"
                            onClick={deleteStudent}
                        >
                            {DELETE}
                        </Button>
                    </Grid>
                </Grid>
                {!loadingStudentInfo ? <Grid item width={'100%'}>
                    {studentInfo?.map((stage, stageIndex) => (
                        <Accordion slotProps={{ transition: { unmountOnExit: true } }} key={stageIndex}>
                            <AccordionSummary sx={{ bgcolor: '#96281B' }} onClick={() => handleClick(stage)}>{<FormControlLabel control={<Checkbox checked={isStageChecked(stage)} />} label={populateStageName(stage.stageId)} sx={{ color: 'black' }} disabled />}</AccordionSummary>
                            <AccordionDetails>
                                {stage.skillCodeDTOs.map((skillCode, skillCodeIndex) => (
                                    <Accordion slotProps={{ transition: { unmountOnExit: true } }} key={skillCodeIndex}>
                                        <AccordionSummary sx={{ bgcolor: 'grey' }}>{populateSkillCodeName(skillCode, skillCodeIndex)}</AccordionSummary>
                                        <AccordionDetails>{skillCode.skillCodeName}</AccordionDetails>
                                        <Divider />
                                        <AccordionDetails>
                                            {skillCode.dataPoints.map((dataPoint, dataPointIndex) => (
                                                <FormControlLabel
                                                    key={dataPointIndex}
                                                    control={
                                                        <Checkbox
                                                            id={'testing'}
                                                            defaultChecked={dataPoint.complete}
                                                            onClick={() => updateSkill(event, dataPoint)}
                                                        />
                                                    }
                                                    label={populateDataPointName(dataPointIndex)} />
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid> : <CircularProgress
                    size={75}
                    sx={{
                        color: '#96281B',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />}
            </Grid>
            <ConfirmationModal isOpen={isConfirmationModalOpen} message={confirmationMessage} confirmationType={confirmationType} buttonText={buttonText} />
        </ThemeProvider>
    )
}

export default StudentInfoPage;
