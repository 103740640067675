const studentInfoState = () => {
    return {
        studentName: '',
        masteredDataPoints: [],
        studentInfo: [],
        isConfirmationModalOpen: false,
        confirmationType: '',
        loadingStudentInfo: false,
        loadingConfirmation: false
    }
}

export default studentInfoState;
