import { DRAWER_VISIBLITY, GET_STUDENT_LIST_FAILURE, GET_STUDENT_LIST_SUCCESS, INIT_GET_STUDENT_LIST, TOGGLE_NEW_STUDENT_MODAL, UPDATE_TEACHER } from "../Constants/ActionTypes";
import { fetch } from "../Services";

export function updateTeacher(uid) {
    return async (dispatch) => {
        const url = `/login/teacher-name/${uid}`;
        const options = {
            method: 'GET'
        };

        const response = await fetch(url, options);
        const responseData = await response.text();

        dispatch({
            type: UPDATE_TEACHER,
            teacherName: responseData
        })
    }
}

export function newStudentModal() {
    return {
        type: TOGGLE_NEW_STUDENT_MODAL
    }
}

function getStudentListSuccess(data) {
    return {
        type: GET_STUDENT_LIST_SUCCESS,
        payload: data
    }
};

function getStudentListFailure() {
    return {
        type: GET_STUDENT_LIST_FAILURE,
        payload: []
    }
};

export function getStudentList(teacherName) {
    teacherName = teacherName.replace(' ', '_')
    return async (dispatch) => {
        const url = `/students/get-students/${teacherName}`
        const options = {
            method: 'GET'
        }

        dispatch({
            type: INIT_GET_STUDENT_LIST
        })

        try {
            const response = await fetch(url, options);
            const responseData = await response.json();
            dispatch(getStudentListSuccess(responseData));
        } catch (error) {
            dispatch(getStudentListFailure());
        }
    };
}

export function setDrawerVisibility(open) {
    return {
        type: DRAWER_VISIBLITY,
        open
    }
}
