import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, Link, Modal, OutlinedInput, TextField, ThemeProvider, Typography, createTheme } from "@mui/material";
import { initializeApp } from 'firebase/app';
import { browserSessionPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendEmailResetPassword, showLoginAlert } from "../../../Actions/HomeActions";
import { ALERT_TYPE_ERROR, ALERT_TYPE_WARNING, CREATE_ACCOUNT_PATH, EMAIL_NOT_VERIFIED, HOME_PATH, METRICS_TITLE, RESET_PASSWORD, SIGN_IN } from "../../../Constants/TextConstants";
import '../../../Styles/common.css';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [resetPassword, setResetPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const showAlert = useSelector(state => state.home.showAlert);
    const alertType = useSelector(state => state.home.alertType);
    const alertMessage = useSelector(state => state.home.alertMessage);

    const firebaseConfig = {
        apiKey: "AIzaSyBoTLmDOj0hJOv02lAF2PVAfGIYJQ_DAhI",
        authDomain: "nsnumeracyproject.firebaseapp.com",
        projectId: "nsnumeracyproject",
        storageBucket: "nsnumeracyproject.appspot.com",
        messagingSenderId: "980983321546",
        appId: "1:980983321546:web:fdd0a62d74873caf06b533",
        measurementId: "G-R1KMMTTCXE"
    };

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    const submitLoginInfo = async () => {
        await setPersistence(auth, browserSessionPersistence);
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredentials) => {
                if (userCredentials.user.emailVerified) {
                    window.location.assign(HOME_PATH);
                } else {
                    showLoginAlert(ALERT_TYPE_WARNING, EMAIL_NOT_VERIFIED);
                }
            })
            .catch((error) => {
                dispatch(showLoginAlert(ALERT_TYPE_ERROR, error.code))
            });
    }

    const forgotPassword = () => {
        dispatch(sendEmailResetPassword(email));
    }

    const theme = createTheme({
        palette: {
            base: {
                main: '#D4D4D4',
                dark: 'grey'
            },
            secondary: {
                main: '#303030',
                dark: 'black'
            }
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open
            >
                <Box
                    sx={{
                        maxWidth: '25rem',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 10,
                        borderRadius: '1rem',
                        p: 4
                    }}
                >
                    <Grid item xs={12} mb={1}>
                        <Typography variant='h4'>{METRICS_TITLE}</Typography>
                    </Grid>
                    {showAlert ? <Alert variant='filled' severity={alertType} sx={{ width: '100' }}>
                        {alertMessage}
                    </Alert> : null}
                    <Grid container rowGap={3} mt={1}>
                        <Grid container item rowGap={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    variant='outlined'
                                    label='Email'
                                    sx={{ width: '100%' }}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            {!resetPassword ?
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" sx={{ width: '100%' }}>
                                        <InputLabel>Password</InputLabel>
                                        <OutlinedInput
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </FormControl>
                                </Grid> : null}
                        </Grid>
                        <Grid container item justifyContent={'start'} xs={6}>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    color='base'
                                    onClick={() => { navigate(CREATE_ACCOUNT_PATH) }}
                                >
                                    Create Account
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent={'end'} xs={6}>
                            <Grid item xs={resetPassword ? 10.2 : 5.25}>
                                <Button
                                    variant='contained'
                                    color='base'
                                    onClick={() => { resetPassword ? forgotPassword() : submitLoginInfo() }}
                                >
                                    {resetPassword ? RESET_PASSWORD : SIGN_IN}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent={'end'} xs={12}>
                        <Link
                            sx={{ margin: '5px 0 0 0' }}
                            color='secondary'
                            className='hoverPointer'
                            onClick={() => { setResetPassword(true) }}
                        >
                            Forgot Password
                        </Link>
                    </Grid>
                </Box>
            </Modal>
        </ThemeProvider>
    )
}

export default Login;
