import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStudentList, newStudentModal, updateTeacher } from '../../../Actions/AppHeaderActions';
import { closeNewStudentAlert, createStudent, showNewStudentAlert } from "../../../Actions/NewStudentActions";
import { ADD_NEW_STUDENT_SUBMIT, ALERT_TYPE_ERROR, CREATE_ACCOUNT_PATH, GRADE_1, GRADE_2, GRADE_3, GRADE_4, GRADE_5, GRADE_6, KINDERGARTEN, LOGIN_PATH, SUCCESS } from "../../../Constants/TextConstants";

const NewStudentModal = (props) => {
    const dispatch = useDispatch();
    const teacherName = useSelector(state => state.appHeader.teacherName)
    const grades = useSelector(state => state.home.grades);
    const showAlert = useSelector(state => state.newStudent.showAlert);
    const alertType = useSelector(state => state.newStudent.alertType);
    const alertMessage = useSelector(state => state.newStudent.alertMessage);
    const loading = useSelector(state => state.newStudent.loading);

    const [grade, setGrade] = useState('');
    const [name, setName] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const auth = getAuth();

    const updateAuth = async () => {
        await new Promise((resolve) => {
            auth.onAuthStateChanged((user) => {
                resolve(user);
            });
        });
    }

    const loadTeacherName = async () => {
        await updateAuth();

        dispatch(updateTeacher(auth.currentUser.uid))
    }

    useEffect(() => {
        if (!teacherName && window.location.pathname.includes(LOGIN_PATH) && window.location.pathname.includes(CREATE_ACCOUNT_PATH)) {
            loadTeacherName();
        }
    }, [])

    useEffect(() => {
        if (name && grade !== '' && !loading) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [name, grade, loading]);

    const theme = createTheme({
        palette: {
            base: {
                main: '#D4D4D4',
                dark: 'grey'
            },
        },
    });

    const toggleNewStudentModal = () => {
        setGrade('');
        setName('');
        dispatch(closeNewStudentAlert())
        dispatch(newStudentModal());
    }

    const createNewStudent = async () => {
        let gradeText;

        switch (grade) {
            case 0:
                gradeText = KINDERGARTEN.toLowerCase()
                break;
            case 1:
                gradeText = GRADE_1.toLowerCase()
                break;
            case 2:
                gradeText = GRADE_2.toLowerCase()
                break;
            case 3:
                gradeText = GRADE_3.toLowerCase()
                break;
            case 4:
                gradeText = GRADE_4.toLowerCase()
                break;
            case 5:
                gradeText = GRADE_5.toLowerCase()
                break;
            case 6:
                gradeText = GRADE_6.toLowerCase()
                break;
            default:
                break;
        }

        try {
            const create = await dispatch(createStudent(name, gradeText, teacherName))

            if (create.status === SUCCESS) {
                toggleNewStudentModal()
                dispatch(getStudentList(teacherName))
            }
        } catch (error) {
            dispatch(showNewStudentAlert(ALERT_TYPE_ERROR, "Failed to add new student"))
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={props.isOpen}
                onClose={toggleNewStudentModal}
            >
                <Box sx={{
                    maxWidth: '25rem',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    borderRadius: '1rem',
                    p: 4
                }}>
                    <IconButton
                        onClick={toggleNewStudentModal}
                        sx={{
                            position: 'absolute',
                            top: 4,
                            right: 4,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Grid container display={'flex'} marginTop={'1rem'}>
                        {showAlert ? <Alert variant='filled' severity={alertType} sx={{ width: '100%', mb: 1 }}>
                            {alertMessage}
                        </Alert> : null}

                        <Grid container item columnGap={'1rem'}>
                            <Grid item>
                                <TextField
                                    label={'Name'}
                                    onChange={(e) => { setName(e.target.value) }}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ minWidth: '10rem' }}>
                                    <InputLabel>Grade</InputLabel>
                                    <Select
                                        onChange={(event) => setGrade(event.target.value)}
                                        value={grade}
                                        label={'Grade'}
                                    >
                                        <MenuItem disabled value={''}>Select A Grade</MenuItem>
                                        {grades.map((gradeLevel, index) => (
                                            <MenuItem key={index} value={index}>{gradeLevel}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent={'flex-end'} marginTop={'1rem'}>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                
                                <Button
                                    variant="contained"
                                    color="base"
                                    onClick={() => {
                                        createNewStudent();
                                    }}
                                    disabled={isButtonDisabled}
                                >
                                    {ADD_NEW_STUDENT_SUBMIT}
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: '#96281B',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </ThemeProvider>
    )
}

export default NewStudentModal;
