import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';

const firebaseConfig = {
    apiKey: "AIzaSyBoTLmDOj0hJOv02lAF2PVAfGIYJQ_DAhI",
    authDomain: "nsnumeracyproject.firebaseapp.com",
    projectId: "nsnumeracyproject",
    storageBucket: "nsnumeracyproject.appspot.com",
    messagingSenderId: "980983321546",
    appId: "1:980983321546:web:fdd0a62d74873caf06b533",
    measurementId: "G-R1KMMTTCXE"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={currentUser}>
      {children}
    </AuthContext.Provider>
  );
};
