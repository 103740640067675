import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDrawerVisibility } from "../../../Actions/AppHeaderActions";
import { viewStudentInfo } from "../../../Actions/StudentInfoActions";

const PopulateGradeInfo = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <ul
            style={props.style}
        >
            {props.studentList.map((student, index) => (
                <li 
                    key={index}
                >
                    <Button
                        color="inherit"
                        onClick={() => {
                            dispatch(setDrawerVisibility(false))
                            navigate(`/student/${student.studentName.replace(' ', '_').toLowerCase()}`)
                            dispatch(viewStudentInfo(student.studentName.replace('_', ' ')))
                        }}
                    >
                        {student.studentName}</Button>
                </li>
            ))}
        </ul>
    )
}

export default PopulateGradeInfo;
