export function CapitalizeTitle(name) {
    return name?.split(' ').map(character => character.charAt(0).toUpperCase() + character.slice(1)).join(' ');
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function removeSlash(string) {
    if (string && string.charAt(string.length - 1) === '/') {
        return string = string.slice(0, -1);
    }
}