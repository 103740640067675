import { SHOW_ACCOUNT_ALERT } from "../Constants/ActionTypes";
import newAccountState from "../State/NewAccountState";

function showAccountAlert(state, action) {
    return {
        ...state,
        showAlert: true,
        alertType: action.alertType,
        alertMessage: action.alertMessage
    }
}

export default function NewAccountReducer(state = newAccountState, action) {
    switch (action.type) {
        case SHOW_ACCOUNT_ALERT:
            return showAccountAlert(state, action);
        default:
            return state;
    }
}