const homeState = () => {
    return {
        teacherName: '',
        isNewStudentModalOpen: false,
        grades: [
            'Kindergarten',
            '1st Grade',
            '2nd Grade',
            '3rd Grade',
            '4th Grade',
            '5th Grade',
            '6th Grade'
        ],
        showAlert: false,
        alertType: '',
        alertMessage: ''
    }
}

export default homeState;
