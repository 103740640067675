import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './Components/App/App';
import Store from './Store/Store';

ReactDOM.render(
    <Provider store={Store}>
        <App />,
    </Provider>,
    document.getElementById('root')
)
