import React from 'react';
import Body from '../Body/Body';

const App = () => {
    return (
        <div>
            <Body />
        </div>
    )
}

export default App;
