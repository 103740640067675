import { CLOSE_CONFIRMATION_MODAL, CLOSE_STUDENT_ALERT, DELETE_STUDENT_SUCCESS, GET_STUDENT_INFO_FAILURE, GET_STUDENT_INFO_SUCCESS, INIT_GET_STUDENT_INFO, INIT_SAVE_OR_DELETE_STUDENT, OPEN_CONFIRMATION_MODAL, SET_STUDENT_NAME, SHOW_STUDENT_ALERT, UPDATE_SKILL, VIEW_STUDENT_INFO } from "../Constants/ActionTypes";
import studentInfoState from "../State/StudentInfoState";

function setStudentName(state, action) {
    return {
        ...state,
        studentName: action.payload
    }
}

function onInitGetStudentInfo(state) {
    return {
        ...state,
        loadingStudentInfo: true
    }
}

function getStudentInfoSuccess(state, action) {
    return {
        ...state,
        studentInfo: action.payload,
        masteredDataPoints: [],
        loadingStudentInfo: false,
        loadingConfirmation: false
    }
}

function getStudentInfoFailure(state) {
    return {
        ...state,
        studentInfo: [],
        loadingStudentInfo: false,
        loadingConfirmation: false
    }
}

function updateStudentSkill(state, action) {
    return {
        ...state,
        masteredDataPoints: action.payload
    }
}

function onDeleteStudentSuccess(state) {
    return {
        ...state,
        loadingConfirmation: false
    }
}

function closeConfirmationModal(state) {
    return {
        ...state,
        isConfirmationModalOpen: false,
        confirmationType: ''
    }
}

function openConfirmationModal(state, action) {
    return {
        ...state,
        isConfirmationModalOpen: true,
        confirmationType: action.payload
    }
}

function showStudentAlert(state, action) {
    return {
        ...state,
        showAlert: true,
        alertType: action.alertType,
        alertMessage: action.alertMessage
    }
}

function closeStudentAlert(state) {
    return {
        ...state,
        showAlert: false,
        alertType: '',
        alertMessage: ''
    }
}

function onViewStudentMessage(state, action) {
    return {
        ...state,
        studentName: action.name
    }
}

function onInitSaveOrDeleteStudent(state) {
    console.log('reducer')
    return {
        ...state,
        loadingConfirmation: true
    }
}

export default function StudentInfoReducer(state = studentInfoState(), action) {
    switch (action.type) {
        case SET_STUDENT_NAME:
            return setStudentName(state, action);
        case INIT_GET_STUDENT_INFO:
            return onInitGetStudentInfo(state);
        case GET_STUDENT_INFO_SUCCESS:
            return getStudentInfoSuccess(state, action);
        case GET_STUDENT_INFO_FAILURE:
            return getStudentInfoFailure(state);
        case UPDATE_SKILL:
            return updateStudentSkill(state, action);
        case DELETE_STUDENT_SUCCESS:
            return onDeleteStudentSuccess(state);
        case CLOSE_CONFIRMATION_MODAL:
            return closeConfirmationModal(state);
        case OPEN_CONFIRMATION_MODAL:
            return openConfirmationModal(state, action);
        case SHOW_STUDENT_ALERT:
            return showStudentAlert(state, action);
        case CLOSE_STUDENT_ALERT:
            return closeStudentAlert(state);
        case VIEW_STUDENT_INFO:
            return onViewStudentMessage(state, action);
        case INIT_SAVE_OR_DELETE_STUDENT:
            return onInitSaveOrDeleteStudent(state);
        default:
            return state
    }
}