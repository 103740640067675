import { CircularProgress } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  GRADE_1,
  GRADE_2,
  GRADE_3,
  GRADE_4,
  GRADE_5,
  GRADE_6,
  KINDERGARTEN,
} from "../../../Constants/TextConstants";
import "../../../Styles/home.css";
import { CapitalizeTitle } from "../../../Utility/CommonUtils";
import PopulateGradeInfo from "./PopulateGradeInfo";

const Home = () => {
  const studentsList = useSelector((state) => state.appHeader.students);
  const loadingStudentList = useSelector(
    (state) => state.appHeader.loadingStudentList
  );
  const [expandedPanel, setExpandedPanel] = useState();

  const onAccordianChange = (accordianID) => {
    !(accordianID === expandedPanel)
      ? setExpandedPanel(accordianID)
      : setExpandedPanel("");
  };

  const KindergartenStudents = studentsList
    ? studentsList.filter(
        (student) => CapitalizeTitle(student.grade) === KINDERGARTEN
      )
    : [];
  const firstGradeStudents = studentsList
    ? studentsList.filter(
        (student) => CapitalizeTitle(student.grade) === GRADE_1
      )
    : [];
  const secondGradeStudents = studentsList
    ? studentsList.filter(
        (student) => CapitalizeTitle(student.grade) === GRADE_2
      )
    : [];
  const thirdGradeStudents = studentsList
    ? studentsList.filter(
        (student) => CapitalizeTitle(student.grade) === GRADE_3
      )
    : [];
  const fourthGradeStudents = studentsList
    ? studentsList.filter(
        (student) => CapitalizeTitle(student.grade) === GRADE_4
      )
    : [];
  const fifthGradeStudents = studentsList
    ? studentsList.filter(
        (student) => CapitalizeTitle(student.grade) === GRADE_5
      )
    : [];
  const sixthGradeStudents = studentsList
    ? studentsList.filter(
        (student) => CapitalizeTitle(student.grade) === GRADE_6
      )
    : [];
  console.log(sixthGradeStudents);
  return (
    <div
      style={{
        margin: "2rem",
      }}
    >
      <Accordion
        expanded={expandedPanel === KINDERGARTEN}
        onChange={() => onAccordianChange(KINDERGARTEN)}
      >
        <AccordionSummary sx={{ bgcolor: "grey" }}>
          {KINDERGARTEN}
        </AccordionSummary>
        <AccordionDetails>
          {!loadingStudentList ? (
            <PopulateGradeInfo
              studentList={KindergartenStudents}
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "5rem",
                listStyle: "none",
                padding: 0,
                flexWrap: "wrap",
              }}
            />
          ) : (
            <CircularProgress
              size={35}
              sx={{
                color: "#96281B",
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanel === GRADE_1}
        onChange={() => onAccordianChange(GRADE_1)}
      >
        <AccordionSummary sx={{ bgcolor: "grey" }}>{GRADE_1}</AccordionSummary>
        <AccordionDetails>
          {!loadingStudentList ? (
            <PopulateGradeInfo
              studentList={firstGradeStudents}
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "5rem",
                listStyle: "none",
                padding: 0,
                flexWrap: "wrap",
              }}
            />
          ) : (
            <CircularProgress
              size={35}
              sx={{
                color: "#96281B",
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanel === GRADE_2}
        onChange={() => onAccordianChange(GRADE_2)}
      >
        <AccordionSummary sx={{ bgcolor: "grey" }}>{GRADE_2}</AccordionSummary>
        <AccordionDetails>
          {!loadingStudentList ? (
            <PopulateGradeInfo
              studentList={secondGradeStudents}
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "5rem",
                listStyle: "none",
                padding: 0,
                flexWrap: "wrap",
              }}
            />
          ) : (
            <CircularProgress
              size={35}
              sx={{
                color: "#96281B",
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanel === GRADE_3}
        onChange={() => onAccordianChange(GRADE_3)}
      >
        <AccordionSummary sx={{ bgcolor: "grey" }}>{GRADE_3}</AccordionSummary>
        <AccordionDetails>
          {!loadingStudentList ? (
            <PopulateGradeInfo
              studentList={thirdGradeStudents}
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "5rem",
                listStyle: "none",
                padding: 0,
                flexWrap: "wrap",
              }}
            />
          ) : (
            <CircularProgress
              size={35}
              sx={{
                color: "#96281B",
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanel === GRADE_4}
        onChange={() => onAccordianChange(GRADE_4)}
      >
        <AccordionSummary sx={{ bgcolor: "grey" }}>{GRADE_4}</AccordionSummary>
        <AccordionDetails>
          {!loadingStudentList ? (
            <PopulateGradeInfo
              studentList={fourthGradeStudents}
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "5rem",
                listStyle: "none",
                padding: 0,
                flexWrap: "wrap",
              }}
            />
          ) : (
            <CircularProgress
              size={35}
              sx={{
                color: "#96281B",
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanel === GRADE_5}
        onChange={() => onAccordianChange(GRADE_5)}
      >
        <AccordionSummary sx={{ bgcolor: "grey" }}>{GRADE_5}</AccordionSummary>
        <AccordionDetails>
          {!loadingStudentList ? (
            <PopulateGradeInfo
              studentList={fifthGradeStudents}
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "5rem",
                listStyle: "none",
                padding: 0,
                flexWrap: "wrap",
              }}
            />
          ) : (
            <CircularProgress
              size={35}
              sx={{
                color: "#96281B",
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanel === GRADE_6}
        onChange={() => onAccordianChange(GRADE_6)}
      >
        <AccordionSummary sx={{ bgcolor: "grey" }}>{GRADE_6}</AccordionSummary>
        <AccordionDetails display={"flex"} flex-wrap={"wrap"}>
          {!loadingStudentList ? (
            <PopulateGradeInfo
              studentList={sixthGradeStudents}
              style={{
                listStyle: "none"
              }}
            />
          ) : (
            <CircularProgress
              size={35}
              sx={{
                color: "#96281B",
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Home;
