import { GET_STUDENT_LIST_FAILURE, GET_STUDENT_LIST_SUCCESS, DRAWER_VISIBLITY as HANDLE_DRAWER_VISIBLITY, INIT_GET_STUDENT_LIST, TOGGLE_NEW_STUDENT_MODAL, UPDATE_TEACHER } from "../Constants/ActionTypes";
import appHeaderState from "../State/AppHeaderState";

function onInitGetStudentList(state) {
    return {
        ...state,
        loadingStudentList: true
    }
}

function getStudentListSuccess(state, action) {
    return {
        ...state,
        loadingStudentList: false,
        students: action.payload
    };
}

function getStudentListFailure(state) {
    return {
        ...state,
        loadingStudentList: false,
        students: []
    };
}

function onUpdateTeacherName(state, action) {
    return {
        ...state,
        teacherName: action.teacherName
    }
}

function onToggleNewStudentModal(state) {
    return {
        ...state,
        isNewStudentModalOpen: !state.isNewStudentModalOpen
    }
}

function onHandleDrawerVisiblity(state, action) {
    return {
        ...state,
        isDrawerOpen: action.open
    }
}

export default function AppHeaderReducer(state = appHeaderState(), action) {
    switch (action.type) {
        case INIT_GET_STUDENT_LIST:
            return onInitGetStudentList(state);
        case GET_STUDENT_LIST_SUCCESS:
            return getStudentListSuccess(state, action);
        case GET_STUDENT_LIST_FAILURE:
            return getStudentListFailure(state);
        case UPDATE_TEACHER:
            return onUpdateTeacherName(state, action);
        case TOGGLE_NEW_STUDENT_MODAL:
            return onToggleNewStudentModal(state);
        case HANDLE_DRAWER_VISIBLITY:
            return onHandleDrawerVisiblity(state, action);
        default:
            return state;
    }
}