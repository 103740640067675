import { CLOSE_NEW_STUDENT_ALERT, INIT_ADD_NEW_STUDENT, SHOW_NEW_STUDENT_ALERT } from "../Constants/ActionTypes";
import { ERROR, SUCCESS } from "../Constants/TextConstants";
import { fetch } from "../Services";

export function showNewStudentAlert(alertType, alertMessage) {
    return {
        type: SHOW_NEW_STUDENT_ALERT,
        showAlert: true,
        alertType,
        alertMessage
    }
}

export function closeNewStudentAlert() {
    return {
        type: CLOSE_NEW_STUDENT_ALERT
    }
}

function initAddNewStudent() {
    return {
        type: INIT_ADD_NEW_STUDENT
    }
}

export function createStudent(studentName, grade, teacherName) {
    return async (dispatch) => {
        const url = '/students/add-new-student'
        const options = {
            method: 'POST',
            body: JSON.stringify({studentName, grade, teacherName})
        }

        dispatch(initAddNewStudent());

        try {
            const response = await fetch(url, options);
            
            if (response.status === 409) {
                throw new Error(response);
            }
            if (response.ok) {
                closeNewStudentAlert();
                return {status: SUCCESS}
            }
        } catch (error) {
            dispatch(showNewStudentAlert(ERROR, await error.text()))
            return {status: ERROR}
        }
    }
}
