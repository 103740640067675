import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { CircularProgress, Grid } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { getAuth } from 'firebase/auth';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getStudentList, newStudentModal, setDrawerVisibility, updateTeacher } from '../../Actions/AppHeaderActions';
import { CREATE_ACCOUNT_PATH, GRADE_1, GRADE_1_PAGE, GRADE_2, GRADE_2_PAGE, GRADE_3, GRADE_3_PAGE, GRADE_4, GRADE_4_PAGE, GRADE_5, GRADE_5_PAGE, GRADE_6, GRADE_6_PAGE, HOME_PATH, KINDERGARTEN, KINDERGARTEN_PAGE, LOGIN_PATH, METRICS_TITLE } from '../../Constants/TextConstants';
import { CapitalizeTitle } from '../../Utility/CommonUtils';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const AppHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const studentList = useSelector((state) => state.appHeader.students);
  const teacherName = useSelector((state) => state.appHeader.teacherName);
  const studentName = useSelector((state) => state.studentInfo.studentName);
  const grade = useSelector((state) => state.gradeInfo.grade);
  const open = useSelector((state) => state.appHeader.isDrawerOpen);
  const loadingStudentList = useSelector((state) => state.appHeader.loadingStudentList);
  const auth = getAuth();

  const addNewStudentModal = () => {
    dispatch(newStudentModal())
  }

  let path = location.pathname;
  let heading;
  if (path.includes('/student/')) {
    heading = CapitalizeTitle(studentName);
  } else if (path.includes('/grades/')) {
    heading = CapitalizeTitle(grade);
  } else {
    heading = METRICS_TITLE
  }

  const handleToggleDrawer = (visibilityStatus) => {
    dispatch(setDrawerVisibility(visibilityStatus));
  };

  const updateAuth = async () => {
    await new Promise((resolve) => {
      auth.onAuthStateChanged((user) => {
        resolve(user);
      });
    });
  }

  const loadTeacherName = async () => {
    await updateAuth();

    if (auth && auth.currentUser && auth.currentUser.uid) {
      dispatch(updateTeacher(auth.currentUser.uid))
    }
  }

  useEffect(() => {
    if (!teacherName && !location.pathname.includes(LOGIN_PATH) && !location.pathname.includes(CREATE_ACCOUNT_PATH)) {
      loadTeacherName();
    }
  }, []);

  useEffect(() => {
    if (teacherName) {
      dispatch(getStudentList(teacherName))
    }
  }, [teacherName]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" open={open}>
          <Toolbar
            style={{ backgroundColor: '#96281B', height: '6rem' }}
          >
            <IconButton
              onClick={() => handleToggleDrawer(true)}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item>
                <Typography variant="h4" noWrap component="div" color={'black'}>
                  {heading}
                </Typography>
              </Grid>
              <Grid item style={{ justifySelf: 'end' }}>
                <Typography variant="h4" noWrap component="div" color={'black'}>
                  {CapitalizeTitle(teacherName)}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              bgcolor: 'grey',
              scrollbarWidth: 'none'
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={() => handleToggleDrawer(false)}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItemButton onClick={() => {
              handleToggleDrawer(false)
              navigate(HOME_PATH);
            }}>
              <ListItemText primary={'Home'} />
            </ListItemButton>
            <ListItemButton onClick={() => addNewStudentModal()}>
              <ListItemText primary={'Add New Student'} />
            </ListItemButton>
          </List>
          <Divider />
          <List>
            <ListItemButton onClick={() => {
              handleToggleDrawer(false)
              navigate('/grades/' + KINDERGARTEN_PAGE)
            }}>
              <ListItemText primary={KINDERGARTEN} />
            </ListItemButton>
            <ListItemButton onClick={() => {
              handleToggleDrawer(false)
              navigate('/grades/' + GRADE_1_PAGE)
            }}>
              <ListItemText primary={GRADE_1} />
            </ListItemButton>
            <ListItemButton onClick={() => {
              handleToggleDrawer(false)
              navigate('/grades/' + GRADE_2_PAGE)
            }}>
              <ListItemText primary={GRADE_2} />
            </ListItemButton>
            <ListItemButton onClick={() => {
              handleToggleDrawer(false)
              navigate('/grades/' + GRADE_3_PAGE)
            }}>
              <ListItemText primary={GRADE_3} />
            </ListItemButton>
            <ListItemButton onClick={() => {
              handleToggleDrawer(false)
              navigate('/grades/' + GRADE_4_PAGE)
            }}>
              <ListItemText primary={GRADE_4} />
            </ListItemButton>
            <ListItemButton onClick={() => {
              handleToggleDrawer(false)
              navigate('/grades/' + GRADE_5_PAGE)
            }}>
              <ListItemText primary={GRADE_5} />
            </ListItemButton>
            <ListItemButton onClick={() => {
              handleToggleDrawer(false)
              navigate('/grades/' + GRADE_6_PAGE)
            }}>
              <ListItemText primary={GRADE_6} />
            </ListItemButton>
          </List>
          <Divider />
          {!loadingStudentList ? <List>
            {studentList.map((student, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton>
                  <ListItemText primary={CapitalizeTitle(student.studentName)} onClick={() => {
                    handleToggleDrawer(false)
                    navigate(`/student/${student.studentName.replace(' ', '_')}`)
                  }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List> : <CircularProgress
            size={24}
            sx={{
              color: '#96281B',
              margin: '1rem'
            }}
          />}
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
        </Main>
      </Box>
    </>
  );
}

export default AppHeader;
