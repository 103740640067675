import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { LOGIN_PATH } from '../../Constants/TextConstants';

const PrivateRoute = ({ children }) => {
    const auth = getAuth();
    const navigate = useNavigate();

    const checkUserAuth = async () => {
        await new Promise((resolve) => {
            auth.onAuthStateChanged((user) => {
                resolve(user);
            });
        });
        
        if (!auth || !auth.currentUser || !auth.currentUser.emailVerified) {
            navigate(LOGIN_PATH);
        }
    }

    checkUserAuth();

    return children;
};

export default PrivateRoute;
