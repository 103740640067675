import { CLOSE_NEW_STUDENT_ALERT, INIT_ADD_NEW_STUDENT, SHOW_NEW_STUDENT_ALERT } from "../Constants/ActionTypes";
import newStudentState from "../State/NewStudentState";

function showNewStudentAlert(state, action) {
    return {
        ...state,
        showAlert: true,
        alertType: action.alertType,
        alertMessage: action.alertMessage
    }
}

function closeNewStudentAlert(state) {
    return {
        ...state,
        showAlert: false,
        alertType: '',
        alertMessage: '',
        loading: false
    }
}

function onInitAddNewStudent(state) {
    return {
        ...state,
        loading: true
    }
}

export default function NewStudentReducer(state = newStudentState(), action) {
    switch (action.type) {
        case SHOW_NEW_STUDENT_ALERT:
            return showNewStudentAlert(state, action);
        case CLOSE_NEW_STUDENT_ALERT:
            return closeNewStudentAlert(state);
        case INIT_ADD_NEW_STUDENT:
            return onInitAddNewStudent(state);
        default:
            return state
    }
}