export function createHeader(isContentTypeRequired = true) {
    let headerConfig = {
        Accept: 'application/json'
    }

    if (isContentTypeRequired) {
        headerConfig = {
            'Content-Type': 'application/json',
            ...headerConfig
        };
    }
    return headerConfig;
}

export function createFetchRequest(
    givenOptions,
    isContentTypeRequired
) {
    const fetchRequest = {
        ...givenOptions
    }
    const defaultHeaders = createHeader(isContentTypeRequired);

    fetchRequest.headers = {
        ...defaultHeaders,
        ...fetchRequest.headers
    };
    return fetchRequest;
}

export function fetch(
    endpoint,
    options,
    isContentTypeRequired
) {
  const baseUrl = `https://nsnumeracyproject-metrics.com${endpoint}`;
  // const baseUrl = `http://localhost:8080${endpoint}`;

    const fetchRequest = createFetchRequest(options, isContentTypeRequired)

    const request = global.fetch(baseUrl, fetchRequest);

    return request.then((response) => {
        if (!response.ok) {
            return Promise.reject(response);
        }
        return response;
    })
}
