import PropTypes from 'prop-types';

const appHeaderState = () => {
    return {
        teacherName: '',
        students: [],
        isNewStudentModalOpen: false,
        isDrawerOpen: false,
        loadingStudentList: false
    }
}

appHeaderState.propTypes = {
    teacherName: PropTypes.string
}

export default appHeaderState;
