const newStudentState = () => {
    return {
        showAlert: false,
        alertType: '',
        alertMessage: '',
        loading: false
    }
}

export default newStudentState;
