import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Box, Button, createTheme, FormControl, Grid, IconButton, InputAdornment, InputLabel, Link, Modal, OutlinedInput, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resetUserPassword, validateResetToken } from "../../../Actions/ResetPasswordActions";
import { LOGIN_PATH, RESET_PASSWORD } from "../../../Constants/TextConstants";
import '../../../Styles/common.css';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const showAlert = useSelector(state => state.resetPassword.showAlert);
    const alertType = useSelector(state => state.resetPassword.alertType);
    const alertMessage = useSelector(state => state.resetPassword.alertMessage);

    useEffect(() => {
        dispatch(validateResetToken(token))
    }, []);

    const theme = createTheme({
        palette: {
          base: {
            main: '#D4D4D4',
            dark: 'grey'
          },
          secondary: {
            main: '#303030',
            dark: 'black'
          }
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Modal open>
                <Box 
                    sx={{
                        maxWidth: '25rem',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 10,
                        borderRadius: '1rem',
                        p: 4
                    }}
                >
                    <Grid container item xs={12} justifyContent={'center'} mb={1}>
                            <Typography variant='h4'>{'Reset Password'}</Typography>
                        </Grid>
                        {showAlert ? <Alert variant='filled' severity={alertType} sx={{width: '100'}}>
                            {alertMessage}
                        </Alert> : null}
                    <Grid container rowGap={3} mt={1}>
                        <Grid container item rowGap={2}>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" sx={{width: '100%'}}>
                                    <InputLabel>New Password</InputLabel>
                                    <OutlinedInput
                                        autoFocus
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        label="New Password"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" sx={{width: '100%'}}>
                                    <InputLabel>Confirm Password</InputLabel>
                                    <OutlinedInput
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        label="Confirm Password"
                                        color={newPassword !== confirmPassword ? 'error' : ''}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item justifyContent={'end'} xs={12}>
                                <Button
                                    variant='contained'
                                    color='base'
                                    disabled={!(newPassword === confirmPassword && !!newPassword)}
                                    onClick={() => dispatch(resetUserPassword(token, newPassword))}
                                >
                                    {RESET_PASSWORD}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent={'end'} xs={12}>
                        <Link
                            sx={{margin: '5px 0 0 0'}}
                            color='secondary'
                            className='hoverPointer'
                            onClick={() => navigate(LOGIN_PATH)}
                        >
                            {'Back to Sign In'}
                        </Link>
                    </Grid>
                </Box>
            </Modal>
        </ThemeProvider>
    )
};

export default ResetPassword;
